import React, { useContext, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import StyledWelcomeHomeItem from './WelcomeHomeItem.Style';
import ImageUploader from '../ImageUploader/ImageUploader';
import Modal from '../Modal';
import LoadingDots from '../LoadingDots';
import CheckBox from '../CheckBox/CheckBox';
import { WelcomeHomeContext } from './WelcomeHomeProvider';
import { UserContext } from '../User';
import WelcomeHomeAutoComplete from './WelcomeHomeAutoComplete';
import { getOrientation, resetOrientation } from '../../utils';
import ImageEditor from '../ImageEditor/ImageEditor';
import EditModal from './EditModal';

const WelcomeHomeItem = ({
  match: {
    params: { id: welcomeHomeItemId },
  },
  history,
}) => {
  const {
    uploadWelcomeHomeImage,
    bookedOpportunities,
    isLoading,
    isSubmitting,
    setConfirmNavigation,
    currentHistory,
  } = useContext(WelcomeHomeContext);

  const { activeLot, user } = useContext(UserContext);

  const [toolTip, setShowToolTip] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [noCustomerFound, setNoCustomerFound] = useState(false);
  const [firstName, setFirstName] = useState({ value: '', error: null });
  const [lastName, setLastName] = useState({ value: '', error: null });
  const [email, setEmail] = useState({ value: '', error: null });
  const [isConsented, setIsConsented] = useState(false);
  const [rawImageData, setRawImageData] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showImageEditModal, setShowImageEditModal] = useState(false);
  const [savedItem, setSavedItem] = useState(null);
  const [isNewImage, setIsNewImage] = useState(false);

  // rawimage data is BIG so we want to release the previous copy from memory before setting new one
  const safeSetRawImageData = newImageData => {
    URL.revokeObjectURL(rawImageData);
    setRawImageData(newImageData);
  };

  const handleCheckBoxChange = event => {
    setIsConsented(!isConsented);
  };

  const showTooltip = () => {
    setShowToolTip(!toolTip);
  };

  const handleSave = useCallback(() => {
    uploadWelcomeHomeImage({
      firstName:
        customer &&
        customer.Name.split(' ')
          .slice(0, -1)
          .join(' '),
      lastName:
        customer &&
        customer.Name.split(' ')
          .slice(-1)
          .join(' '),
      email: customer && customer.Email,
      welcomeHomeImage: rawImageData,
      hasConsentedToMediaRelease: isConsented,
      isShownInGallery: isConsented,
      vantageId: customer ? customer.Id : '',
      file: imageFile,
    }).then(response => {
      setSavedItem(response);
      setShowModal(true);
    });
  }, [
    uploadWelcomeHomeImage,
    firstName,
    lastName,
    email,
    isConsented,
    imageFile,
    rawImageData,
    customer,
  ]);

  const fullName =
    savedItem && savedItem.FirstName === null
      ? savedItem.Name
      : savedItem
        ? `${savedItem.FirstName} ${savedItem.LastName}`
        : null;

  const handleRemoveImage = () => {
    safeSetRawImageData(null);
  };

  const handleAdjustImage = () => {
    setShowImageEditModal(true);
  };

  const cancelEditingImage = () => {
    setShowImageEditModal(false);
  };

  const handleUpload = imageData => {

    let origOrientation = null;
    getOrientation(imageData.file, orientation => {
      origOrientation = orientation;
      resetOrientation(
        URL.createObjectURL(imageData.file),
        origOrientation,
        (resetResultImageUrl, resetResultImageBlob) => {
          var file = new File([resetResultImageBlob], imageData.file.name);
          setImageFile(file);
          safeSetRawImageData(resetResultImageUrl);
        }
      );

      setIsNewImage(true);
      setShowImageEditModal(true);
    });
  };

  const doneEditingImage = editedImage => {
    setImageFile(editedImage.file);
    safeSetRawImageData(editedImage.dataUri);
    setShowImageEditModal(false);
  };

  const toggleModal = () => {
    if (showModal) {
      setShowModal(false);
      setConfirmNavigation(true);
      setTimeout(() => {
        history.push(`/content/welcome-home`);
      }, 1);
      return;
    }
    setShowModal(!showModal);
  };

  const tooltipStyle = {
    display: toolTip ? 'block' : 'none',
  };

  const name = customer && customer.Name;

  if (isLoading) {
    return <LoadingDots />;
  }
  return (
    <StyledWelcomeHomeItem>
      {showImageEditModal && (
        <ImageEditor
          imageData={rawImageData}
          imageFileNameOverride="croppedImage.jpg"
          onDoneEditing={doneEditingImage}
          onCancelEditing={cancelEditingImage}
          aspect={1}
          hideCancel={isNewImage}
        />
      )}
      <div className="main-column">
        {!rawImageData && (
          <ImageUploader
            uploadFunction={handleUpload}
            stageImages={false}
            allowMultiple={false}
            headerText={`Upload Welcome Home image (required)`}
            minHeight={600}
            minWidth={600}
            isWelcomeHomeImage={true}
          />
        )}
        {rawImageData && (
          <div className="image-field card">
            <div className="image-wrapper">
              <img src={rawImageData} alt={`${firstName} ${lastName}`} />
            </div>
            <div className="current-image-options">
              <p onClick={handleRemoveImage}>Replace Image</p>
              <p onClick={handleAdjustImage}>Adjust Image</p>
            </div>
          </div>
        )}

        <WelcomeHomeAutoComplete
          defaultValue={''}
          onSelect={value => {
            setCustomer(value);
          }}
          entries={bookedOpportunities}
          tabIndex={0}
          setNoCustomerFound={setNoCustomerFound}
          setCustomer={setCustomer}
          customer={customer}
        />
        <div className="submit-container">
          <div className="signed-release-container">
            <label htmlFor="isConsented">
              <div className="signed-release-label">
                I have a record of a signed Welcome Home image release, for each
                person shown in the photo, giving consent to use this image.
                <div className="signed-release-sub-label">
                  Do not check this box without written consent. Images without
                  consent will not be displayed on your website.
                </div>
              </div>

              <CheckBox
                name="isConsented"
                id="isConsented"
                checked={isConsented}
                width="18px"
                height="18px"
                borderRadius="3px"
                onChange={handleCheckBoxChange}
                className="is-consented-checkbox"
              />
            </label>
          </div>
          {!isSubmitting && (
            <button
              type="submit"
              id="submitBtn"
              disabled={
                noCustomerFound ||
                (customer === null && !noCustomerFound) ||
                rawImageData === ''
              }
              onClick={handleSave}
            >
              Submit
            </button>
          )}
          {isSubmitting && <LoadingDots className="form-submit-loading" />}
        </div>
      </div>
      {savedItem && (
        <EditModal
          fullName={fullName}
          galleryCheck={savedItem.IsShownInGallery}
          homeCheck={savedItem.SortOrder ? true : false}
          currentItem={savedItem}
          show={showModal}
          useCloseIcon={true}
          closeCallback={toggleModal}
          title="Success!"
          description={
            `You have added a welcome home image for ${name}.` +
            (savedItem.HasConsentedToMediaRelease
              ? ' Choose where to display this image on your website.'
              : '')
          }
          alwaysAllowSave={true}
        />
      )}
    </StyledWelcomeHomeItem>
  );
};

WelcomeHomeItem.propTypes = {
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default WelcomeHomeItem;
