import React from 'react';
import StyledNoImagesAvailable from './NoImagesAvailable.Style';
import HomeSvg from '../HomeSvg';

const NoImagesAvailable = ({ message, type }) => (
  <StyledNoImagesAvailable>
    <div className="image-wrapper">
      <HomeSvg />
    </div>
    <p className="message">
      <strong>No {type} have been uploaded.</strong>
    </p>
  </StyledNoImagesAvailable>
);

export default NoImagesAvailable;
