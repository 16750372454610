// only works with cors settings on server
export const imageUrlToBlob = async url =>
  new Promise((resolve, reject) => {


    try {
      var img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {

        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d', { preserveDrawingBuffer: true });
        canvas.height = this.height;
        canvas.width = this.width;
        ctx.drawImage(this, 0, 0);

        // convert to url and blob
        try {
          canvas.toBlob(
            function (blob) {
              let url = URL.createObjectURL(blob);
              resolve(url, blob);
            },
            'image/jpeg',
            0.7
          );
          canvas = null;
        } catch (e) {

        }
      };
      img.src = url;
    } catch (e) {

    }
  });

export const fileToBlob = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export const base64ToFile = (url, fileName) => {
  var blobBin = atob(url.split(',')[1]);
  var array = [];
  for (var i = 0; i < blobBin.length; i++) {
    array.push(blobBin.charCodeAt(i));
  }
  var blob = new Blob([new Uint8Array(array)], {
    type: 'image/jpeg',
  });
  var file = new File([blob], fileName, { type: 'image/jpeg' });
  return file;
};

function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export const getCroppedImg = async (
  imageSrc,
  imageFileNameOverride,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement('canvas');
  croppedCanvas.style.background = 'white';
  const croppedCtx = croppedCanvas.getContext('2d');

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;
  croppedCtx.fillStyle = 'white';
  croppedCtx.fillRect(0, 0, croppedCanvas.width, croppedCanvas.height);

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );
  // As a blob
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob(
      blob => {
        var file = new File(
          [blob],
          imageFileNameOverride ? imageFileNameOverride : 'canvasImage.jpg',
          { type: 'image/jpeg' }
        );
        let url = URL.createObjectURL(blob);
        resolve({ dataUri: url, file: file });
      },
      'image/jpeg',
      0.7
    );
  });
};


