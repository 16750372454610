import React from 'react';

const SelectCheckmark = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#4F9E44" />
      <path
        d="M6.30112 9.84478C6.0036 9.54707 5.52108 9.54692 5.22338 9.84444C4.92567 10.142 4.92552 10.6245 5.22304 10.9222L7.6495 13.3502C7.94708 13.6479 8.42973 13.648 8.72741 13.3503L14.7768 7.30095C15.0744 7.00334 15.0744 6.52082 14.7768 6.22321C14.4792 5.9256 13.9967 5.9256 13.699 6.22321L8.18871 11.7336L6.30112 9.84478Z"
        fill="white"
      />
    </svg>
  );
};
export default SelectCheckmark;
