import React, { useContext } from 'react';
import StyledHomepage from './Homepage.Style';
import { Link } from 'react-router-dom';
import { UserContext } from './../User';
import HomesDisplayIcon from './icons/HomesDisplayIcon';
import WelcomeHomeIcon from './icons/WelcomeHomeIcon';
import SiteContentIcon from './icons/SiteContentIcon';

const Homepage = () => {
  const {
    user: { FirstName },
  } = useContext(UserContext);
  return (
    <StyledHomepage>
      <h1 className="headline">
        Welcome Back{FirstName ? ', ' + FirstName + '!' : null}
      </h1>
      <div className="buttons">
        <Link to="/content/welcome-home/new">
          <div className="icon">
            <WelcomeHomeIcon />
          </div>
          <p>
            <span>Upload Welcome Home Images</span>
          </p>
        </Link>
        <Link to="/homes/on-display">
          <div className="icon">
            <HomesDisplayIcon />
          </div>

          <p>
            <span>Manage Inventory</span>
          </p>
        </Link>

        <Link to="/content/news">
          <div className="icon">
            <SiteContentIcon />
          </div>

          <p>
            <span>Manage Content</span>
          </p>
        </Link>
      </div>
    </StyledHomepage>
  );
};

export default Homepage;
