import React from 'react';
import PropTypes from 'prop-types';

const Magnify = ({ color, className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={className || ''}
    onClick={onClick}
  >
    <path
      fill={color}
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.03121 3C11.3784 3 13.2812 4.90286 13.2812 7.25C13.2812 9.59714 11.3784 11.5 9.03121 11.5C8.0379 11.5 7.12416 11.1592 6.40054 10.5882L3.8534 13.1344C3.6581 13.3296 3.34152 13.3296 3.14629 13.1343C2.95107 12.939 2.95112 12.6224 3.14642 12.4272L5.69339 9.8811C5.12214 9.15741 4.78121 8.24351 4.78121 7.25C4.78121 4.90286 6.68407 3 9.03121 3ZM6.72653 9.54145C6.14227 8.95384 5.78121 8.14407 5.78121 7.25C5.78121 5.45514 7.23635 4 9.03121 4C10.8261 4 12.2812 5.45514 12.2812 7.25C12.2812 9.04486 10.8261 10.5 9.03121 10.5C8.13737 10.5 7.32778 10.1391 6.7402 9.55511L6.73352 9.54831L6.72653 9.54145ZM8.53121 7.75V8.8442C8.53121 9.12034 8.75507 9.3442 9.03121 9.3442C9.30735 9.3442 9.53121 9.12034 9.53121 8.8442V7.75H10.6255C10.9017 7.75 11.1255 7.52614 11.1255 7.25C11.1255 6.97386 10.9017 6.75 10.6255 6.75H9.53121V5.6562C9.53121 5.38006 9.30735 5.1562 9.03121 5.1562C8.75507 5.1562 8.53121 5.38006 8.53121 5.6562V6.75H7.43751C7.16137 6.75 6.93751 6.97386 6.93751 7.25C6.93751 7.52614 7.16137 7.75 7.43751 7.75H8.53121Z"
    />
  </svg>

  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width="16"
  //   height="13"
  //   viewBox="0 0 16 13"
  // >

  //   <path
  //     fill={color}
  //     fillRule="nonzero"
  //     d="M14.113.614l1.462 1.364L5.511 12.762.113 6.978l1.462-1.364L5.511 9.83z"
  //   />
  // </svg>
);

Magnify.propTypes = {
  color: PropTypes.string,
};

Magnify.defaultProps = {
  color: '#ffffff',
};

export default Magnify;
