import styled from 'styled-components';

const StyledImageEditor = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 990;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000055;

  .hide {
    visibility: hidden;
  }
  .overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 995;
    background: rgba(0, 0, 0, 0.85);
  }

  .overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 995;
    background: rgba(0, 0, 0, 0.85);
  }

  .edit-image-container {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    max-height: 100vh;

    z-index: 999;
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    .cropper-container {
      flex-grow: 2;
      position: relative;
    }
  }

  .edit-image-container-read-only {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    max-height: 100vh;
  }

  .image-container {
    justify-content: center;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .read-only-image {
    object-fit: contain;
  }

  .loader {
    margin-top: 0px !important;

    .bubble {
      background-color: white !important;
    }
  }

  .btns-container {
    border-top: 1px solid #e4e5e7;
    border-bottom: 1px solid grey;
    padding-top: 17px;
    background-color: white;

    .buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 31px;
      padding: 5px;

      & > * {
        margin: 5px;
      }

      label {
        display: block;
      }

      .MuiSlider-thumb {
        color: #00b0ac !important;
      }

      .MuiSlider-track {
        color: #00b0ac !important;
      }

      .MuiSlider-root {
        flex-grow: 0.5;
      }

      .cancel-button {
        background: #e4e5e7;
        font: 600 12px/ 100% source-sans-pro, sans-serif;
        color: #9b9b9b;
        letter-spacing: 0.5px;
        padding: 15px 0;
        width: 195px;
        margin-left: 83px;

        @media screen and (max-width: 767px) {
          width: 102px;
          margin-left: 10px;
          padding-left: 0px;
        }
      }

      .save-button {
        background: #00b0ac;
        font: 600 12px/ 100% source-sans-pro, sans-serif;
        color: #fff;
        letter-spacing: 0.5px;
        padding: 15px 0;
        width: 195px;
        margin-right: 83px;

        @media screen and (max-width: 767px) {
          width: 102px;
          margin-right: 20px;
        }
      }

      .save-button-read-only {
        margin-right: 0;
      }

      button {
        margin-block-start: 0;
        margin-block-end: 0;
        border: none;
        border-radius: 0;
        &:hover,
        &:focus {
          opacity: 0.8;
          cursor: pointer;
        }
      }

      .loading-btn {
        background: #e4e5e7;
        &:hover {
          cursor: not-allowed;
          opacity: 1;
        }
        .loader {
          margin-top: 0px !important;
        }
      }
    }

    .buttons-read-only-mode {
      flex-direction: row;
      justify-content: center;
    }
  }
`;

export default StyledImageEditor;
