import React, { useEffect, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import StyledEditImageModal from './EditImageModal.Style';
import Modal from '../Modal';
import { IMAGE_TYPES } from '../../constants';
import CloseButton from '../../icons/CloseButton';
import editImageModalReducer from '../../reducers/editImagesModal';
import { formatDateWithTime } from '../../utils';
import LoadingDots from '../LoadingDots';

const EditImageModal = ({
  closeCopy,
  saveCopy,
  show,
  closeCallback,
  saveFunction,
  deleteFunction,
  rooms,
  image,
  isUpdating,
  isLoading,
  imageType,
  allowFloorPlan,
}) => {
  const [state, dispatch] = useReducer(editImageModalReducer, {
    initialImage: { ...image },
    stagedImage: { ...image },
    showSaveModal: false,
  });
  const roomOptions = rooms
    ? Object.keys(rooms).map(id => {
        const Description = rooms[id];
        return (
          <option key={id} value={id}>
            {Description}
          </option>
        );
      })
    : null;

  const stagedImage = useMemo(() => {
    return state.stagedImage;
  }, [state.stagedImage]);

  const showSaveModal = useMemo(() => {
    return state.showSaveModal;
  }, [state.showSaveModal]);

  const imageUpdated = useMemo(() => {
    return !isEqual(state.stagedImage, state.initialImage);
  }, [state.initialImage, state.stagedImage]);

  const handleClose = () => {
    if (imageUpdated) {
      dispatch({ type: 'SET_SHOW_SAVE_MODAL', payload: imageUpdated });
    } else {
      closeCallback();
    }
  };

  const handleSave = () => {
    if (imageUpdated) {
      saveFunction(stagedImage);
      closeCallback();
    }
  };

  useEffect(() => {
    if (!state.initialImage || state.initialImage === {}) {
      dispatch({ type: 'SET_INITIAL_IMAGE', payload: state.stagedImage });
    }
  }, [image, state.stagedImage, state.initialImage]);

  const groupOptions = Object.keys(IMAGE_TYPES).map(imageType => {
    // If there is already an inventory floor plan image record (saved or selected), don't include the "Floor Plan" option

    if (imageType === 'FLP' && allowFloorPlan !== true) {
      return;
    }

    return (
      <option key={imageType} value={imageType}>
        {IMAGE_TYPES[imageType]}
      </option>
    );
  });

  return (
    <>
      <StyledEditImageModal
        className="modal"
        style={{ display: show ? 'block' : 'none' }}
      >
        <div role="presentation" className="overlay" onClick={handleClose} />
        <div className="modal-content">
          <div className="content-container">
            <div className="img-container">
              <img
                src={`${image.Reference}?width=141`}
                alt={image.RoomDescription}
              />
              <p className="uploaded-by">
                {`Uploaded by ${image.LastModifiedBy}`}
              </p>
              <p className="uploaded-by">
                {`on ${formatDateWithTime(image.LastModificationDate)}`}
              </p>
            </div>
            <div className="spacer" />
            <div className="input-group">
              {imageType === 'home' && (
                <div className="inputs">
                  <div className="input-container">
                    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                    <label htmlFor="image-group">
                      Group
                      <select
                        id="image-group"
                        value={stagedImage.ImageTypeAcronym}
                        onChange={event => {
                          dispatch({
                            type: 'UPDATE_GROUP',
                            payload: event.target.value,
                          });
                        }}
                      >
                        {groupOptions}
                      </select>
                    </label>
                  </div>
                  {stagedImage.ImageTypeAcronym === 'INT' && (
                    <div className="input-container">
                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                      <label htmlFor="image-room">
                        Room
                        <select
                          id="image-room"
                          value={stagedImage.RoomId}
                          onChange={event => {
                            dispatch({
                              type: 'UPDATE_ROOM',
                              payload: {
                                Id: event.target.value,
                                Description: rooms[event.target.value],
                              },
                            });
                          }}
                        >
                          {roomOptions}
                        </select>
                      </label>
                    </div>
                  )}
                </div>
              )}

              <div className="inputs">
                <div className="input-container full-width">
                  <label htmlFor="image-caption">
                    Caption
                    <textarea
                      id="image-caption"
                      value={stagedImage.Caption}
                      onChange={event => {
                        dispatch({
                          type: 'UPDATE_CAPTION',
                          payload: event.target.value,
                        });
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          {(isUpdating || isLoading) && (
            <div className="btns-container">
              <LoadingDots />
            </div>
          )}
          {!(isUpdating || isLoading) && (
            <div className="btns-container">
              <div className="buttons">
                <button
                  type="button"
                  className="cancel-button"
                  onClick={handleClose}
                >
                  {closeCopy}
                </button>
                <div className="spacer" />
                <button
                  type="button"
                  className="save-button"
                  onClick={handleSave}
                  disabled={!imageUpdated}
                >
                  {saveCopy}
                </button>
              </div>
              <button
                type="button"
                onClick={deleteFunction}
                className="delete-image"
              >
                <CloseButton />
                DELETE IMAGE
              </button>
            </div>
          )}
        </div>
      </StyledEditImageModal>
      <Modal
        show={showSaveModal}
        modalHeadline="Save Changes?"
        modalBody="Would you like to save your changes?"
        saveCopy="SAVE CHANGES"
        closeCopy="DISCARD CHANGES"
        closeCallback={() => {
          dispatch({
            type: 'RESET_STAGED_DETAILS',
          });
          dispatch({ type: 'SET_SHOW_SAVE_MODAL', payload: false });
          closeCallback();
        }}
        discardCallback={() => {
          dispatch({
            type: 'RESET_STAGED_DETAILS',
          });
          dispatch({ type: 'SET_SHOW_SAVE_MODAL', payload: false });
          closeCallback();
        }}
        saveCallback={() => {
          dispatch({ type: 'SET_SHOW_SAVE_MODAL', payload: false });
          saveFunction(stagedImage);
          closeCallback();
        }}
      />
    </>
  );
};

EditImageModal.propTypes = {
  closeCopy: PropTypes.string,
  saveCopy: PropTypes.string,
  show: PropTypes.bool,
  closeCallback: PropTypes.func.isRequired,
  saveFunction: PropTypes.func.isRequired,
  deleteFunction: PropTypes.func.isRequired,
  image: PropTypes.shape({
    LastModifiedBy: PropTypes.string,
    LastModificationDate: PropTypes.string,
    Reference: PropTypes.string,
    RoomDescription: PropTypes.string,
  }).isRequired,
  rooms: PropTypes.shape(),
};

EditImageModal.defaultProps = {
  closeCopy: '',
  saveCopy: '',
  show: false,
  rooms: null,
  imageType: 'home',
};

export default EditImageModal;
