import styled from 'styled-components';

const StyledEditModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 990;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content {
    @media screen and (max-width: 500px) {
      padding: 15px;
    }

   width: 85%;
   height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .edit-modal-container {
    display: flex;
    position: relative;
    gap: 30px;
    @media screen and (max-width: 500px) {
      flex-direction: column;
      gap: 15px;
    }
  }

  .edit-modal-left {
    max-width: 50%;

    @media screen and (max-width: 500px) {
      max-width: initial;
    }
  }

  .edit-modal-right {
    flex: 1 0 230px;
    position: relative;
    @media screen and (max-width: 500px) {
      flex: 1 0 auto;
    }
    label {
      flex-direction: row !important;
      width: 100%;
      padding: 0px;
      justify-content: space-between;
    }
  }

  .toggle-box {
    background-color: white;
    box-shadow: 0 1px 4px 0 rgb(58 67 77 / 12%);
    position: relative;
    padding: 15px;
    margin-bottom: 30px;
    @media screen and (max-width: 500px) {
      margin-bottom: 15px;
    }
  }

  .current-image-box {
    background: white;
  }

  .current-image {
    background: white;
    display: flex;
    flex-wrap: nowrap;
    flex-flow: column;
    img {
      height: auto;
      width: 100%;
      max-width: 400px;
    }
  }

  p {
    font: 400 15px/140% source-sans-pro;
    color: #7f8083;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 0
    margin-bottom: 30px
    text-align: ${props => (props.useCloseIcon ? 'center' : 'left')};
  }

  .current-image-options {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    background: white;
    padding: 30px;
    @media screen and (max-width: 650px) {
      padding: 15px;
    }
    p {
      text-transform: uppercase;
      text-decoration: underline;
      cursor: pointer;
      margin: 0px;
      color: rgb(0, 117, 201);
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 3px;
      @media screen and (max-width: 650px) {
        font-size: 13px;
      }
    }
  }

  .signed-consent-warning {
    font-size: small;
    font-weight: bold;
  }

  .save-btn {
    width: 100%;
    border-radius: 2px;
    border: none;
    outline: none;
    background: #00b0ac;
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font: 600 14px/100% source-sans-pro;
    letter-spacing: 0.045em;
    text-transform: uppercase;
    text-align: center;
    padding: 20px 0;
    height: 63px;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
  .loading-btn {
    background: #e4e5e7;
    &:hover {
      cursor: not-allowed;
      opacity: 1;
    }
    .loader {
      margin-top: 0px !important;
    }
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 995;
    background: rgba(0, 0, 0, 0.85);
  }

  .modal-content {
    z-index: 999;
    position: relative;
    background: #fff;
    box-sizing: border-box;
    padding: 15px 30px 0;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    background: #f3f5f7;

    .description {
      margin-bottom: 15px;
    }
  }

  .img-placeholder {
    width: 200px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
  }

  .close-modal-icon {
    position: relative;
    z-index: 9999;
    background: none;
    border: none;
    outline: nonex;
    left: 98%;

    &:hover {
      cursor: pointer;
    }
  }

  h2 {
    font: 600 18px/122% source-sans-pro;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 15px;
    text-align: ${props => (props.useCloseIcon ? 'center' : 'left')};
  }

  .modal-text {
    display: ${props => (props.useCloseIcon ? 'block' : 'flex')};
    justify-content: space-between;
    flex-wrap: wrap;

    img {
      max-width: 150px;
      display: block;
      margin: 0 auto;
    }
  }

  p {
    font: 400 15px/140% source-sans-pro;
    color: #7f8083;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: ${props => (props.useCloseIcon ? '20px' : '0')};
    margin-bottom: ${props => (props.useCloseIcon ? '0' : '50px')};
    text-align: ${props => (props.useCloseIcon ? 'center' : 'left')};
  }

  .btns-container {
    display: flex;
    justify-content: center;
    border-top: none;
    padding-top: 0;
    margin-top: 30px;
    
    bottom: 0;
    width: 100%;

    .ok-btn {
      background: #00b0ac;
      font: 600 12px/ 100% source-sans-pro, sans-serif;
      color: #fff;
      letter-spacing: 0.5px;
      padding: 15px 0;
      width: 195px;
      margin: 0 auto;
      border-radius: 5px;

      &:hover {
        color: #fff;
        cursor: pointer;
        opacity: 0.9;
      }
    }

    button {
      font: 600 14px/100% source-sans-pro;
      color: #0075c9;
      letter-spacing: 1px;
      margin-block-start: 0;
      margin-block-end: 0;
      border: none;
      background: none;
      border-radius: 0;
      padding: 4px;
      transition: color 0.3s;
      &:hover,
      &:focus {
        color: #003f8c;
        cursor: pointer;
      }
    }
  }
`;

export default StyledEditModal;
