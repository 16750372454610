import TagManager from 'react-gtm-module';

// This strips specified keys and values from an object allowing us to pass only what necessary to lower level components
export const pick = keys => obj =>
  keys.reduce((acc, det) => ({ ...acc, [det]: obj[det] }), {});

/**
 * Sorts an array of objects by a given key.
 *
 * @param {object[]} list An array of objects to sort.
 * @param {string} key The key to sort by.
 * @param {boolean} [ascending=true] Set to false for a descending sort.
 *
 * @returns {array} The sorted array.
 */
export const sortByKey = (list, key, ascending = true) => {
  if (!list) return list;
  return list.sort((first, second) => {
    const { [key]: firstVal } = first;
    const { [key]: secondVal } = second;
    return ascending ? firstVal - secondVal : secondVal - firstVal;
  });
};

/**
 * Ensure a given image reference is a fully qualified URL
 *
 * @param {string} reference Image reference to qualify
 *
 * @returns {string} The fully qualified URL for the given reference
 */
export const qualifyUrl = reference => {
  const qualified = new RegExp('^(?:[a-z]+:)?//', 'i');
  return qualified.test(reference)
    ? reference.replace('dev.cct-pubweb', 'itg.cct-pubweb')
    : `https://api.claytonhomes.itg.cct-pubweb.com/images/${reference}`;
};

/**
 * Loops through array of image objects, ensuring all references are fully qualified URLs
 *
 * @param {object[]} images An array of image objects.
 *
 * @returns {array} The original array of objects with qualified references ensured.
 */
export const qualifyImageRefs = images => {
  if (images && images.length > 0) {
    return images.map(image => {
      return {
        ...image,
        Reference: qualifyUrl(image.Reference),
      };
    });
  }
  return [];
};

/**
 * Reorders a given array based on a starting index to ending index
 *
 * @param {[]} array The array to be reordered
 * @param {int} startIndex The initial index of the item to be moved
 * @param {int} endIndex The target index for the item
 * @returns {[]} The reordered array
 */
export const reorderArray = (array, startIndex, endIndex) => {
  const newArray = [...array];
  const [removed] = newArray.splice(startIndex, 1);
  newArray.splice(endIndex, 0, removed);
  return newArray;
};

/**
 * Formats a date string to match designed date fields: MM/DD/YYYY
 *
 * @param {string} date
 * @returns {string} The formatted date string
 */
export const formatDate = date => {
  const creationDate = new Date(date);
  const year = creationDate.getFullYear();
  let month = (1 + creationDate.getMonth()).toString();
  month = month.length > 1 ? month : `0${month}`;

  let day = creationDate.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  return `${month}/${day}/${year}`;
};

/**
 * Formats a .net date string to match designed date fields: MM/DD/YYYY H:M
 *
 * @param {string} date
 * @returns {string} The formatted date string
 */
export const formatDateWithTime = date => {
  return new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  }).format(Date.parse(date));
};

/**
 * Formats the weekday name to two letters.
 *
 * @param date
 * @returns {string} The formatted weekday
 */
export const formatShortWeekday = date => {
  return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'][date.getDay()];
};

/**
 * Calculates the E-App pin based on dealer id
 *
 * @param dealerId
 * @returns {string} the E-App pin
 */
export const getEAppPin = dealerId => {
  return (dealerId * dealerId + 7655).toString(16);
};

export const formatPhoneNumber = phoneNumberString => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');

  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumberString;
};

export const findHomeByWildCardId = (homesList, wildCardId) => {
  if (
    wildCardId == null ||
    !(typeof homesList === 'object' && homesList !== null)
  )
    return null;

  // try Id, InventoryId, SerialNumber, or modelNumber
  const potentialHome = Object.values(homesList).find(
    h =>
      h.Id === wildCardId ||
      h.InventoryId == wildCardId ||
      h.stockNumber === wildCardId ||
      h.serialNumber === wildCardId
  );

  return potentialHome;
};

export const getUniqueHomeLotId = home => {
  return (
    (home.Id ? home.Id : '*') +
    '-' +
    (home.InventoryId ? home.InventoryId : '*') +
    '-' +
    (home.ModelNumber ? home.ModelNumber : '*')
  );
};

export const isValidEmail = value => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(value);
};

export const isValidPhone = value => {
  const phoneRegex = /^(\d{3})(\d{3})(\d{4})$/;
  return phoneRegex.test(value);
};

export const isValidZip = value => {
  const regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  return regex.test(value);
};

export const canEditVisibility = home => {
  return !(
    home.ErrorRequiresTwoImages ||
    home.ErrorRequiresLandImage ||
    home.ErrorRequiresFloorPlanImage ||
    home.ErrorRequiresAddress ||
    home.ErrorRequiresAcres ||
    home.ErrorRequiresBrokerageName ||
    home.ErrorRequiresBrokerageContact
  );
};

export const canEditSaleHome = home => {
  return (
    home.IsPublished &&
    !(
      home.ErrorUnpublishedByDealer ||
      home.IsSold ||
      home.IsLand ||
      home.ErrorRequiresTwoImages ||
      home.ErrorRequiresLandImage ||
      home.ErrorRequiresFloorPlanImage ||
      home.ErrorRequiresAddress ||
      home.ErrorRequiresAcres ||
      home.ErrorRequiresBrokerageName ||
      home.ErrorRequiresBrokerageContact
    )
  );
};

export const canEditSticker = home => {
  // currently same for sale home toggle but may change
  return canEditSaleHome(home);
};

//gets the image's current orientation
export const getOrientation = (file, callback) => {
  const reader = new FileReader();
  reader.onload = e => {
    const view = new DataView(e.target.result);
    if (view.getUint16(0, false) != 0xffd8) {
      return callback(-2);
    }
    let length = view.byteLength,
      offset = 2;
    while (offset < length) {
      if (view.getUint16(offset + 2, false) <= 8) return callback(-1);
      let marker = view.getUint16(offset, false);
      offset += 2;
      if (marker == 0xffe1) {
        if (view.getUint32((offset += 2), false) != 0x45786966) {
          return callback(-1);
        }

        let little = view.getUint16((offset += 6), false) == 0x4949;
        offset += view.getUint32(offset + 4, little);
        let tags = view.getUint16(offset, little);
        offset += 2;
        for (let i = 0; i < tags; i++) {
          if (view.getUint16(offset + i * 12, little) == 0x0112) {
            return callback(view.getUint16(offset + i * 12 + 8, little));
          }
        }
      } else if ((marker & 0xff00) != 0xff00) {
        break;
      } else {
        offset += view.getUint16(offset, false);
      }
    }
    return callback(-1);
  };
  reader.readAsArrayBuffer(file);
};

export const isMobile = (size = 1051) => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < size;
  }
};

//resets the image's orientation to 1 or portrait
export const resetOrientation = (srcImageFile, srcOrientation, callback) => {
  try {
    const img = new Image();

    img.onload = () => {
      let width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d', { preserveDrawingBuffer: true });

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // convert to url and blob
      try {
        canvas.toBlob(
          function (blob) {
            let url = URL.createObjectURL(blob);
            callback(url, blob);
          },
          'image/jpeg',
          0.7
        );
      } catch (e) {


      }
    };

    img.src = srcImageFile;
  } catch (e) {

  }
};

export const isLocalDev = () => {
  return window.location.href.indexOf('localhost') != -1;
};

export const isDev = () => {
  return window.location.href.indexOf('.dev') != -1;
};

export const isItg = () => {
  return window.location.href.indexOf('.itg') != -1;
};

export const isQua = () => {
  return window.location.href.indexOf('.qua') != -1;
};

export const isProd = () => {
  return !(isLocalDev() || isDev() || isItg() || isQua());
};

// insert text at caret stuff
function getInputSelection(el) {
  var start = 0,
    end = 0,
    normalizedValue,
    range,
    textInputRange,
    len,
    endRange;

  if (
    typeof el.selectionStart == 'number' &&
    typeof el.selectionEnd == 'number'
  ) {
    start = el.selectionStart;
    end = el.selectionEnd;
  } else {
    range = document.selection.createRange();

    if (range && range.parentElement() == el) {
      len = el.value.length;
      normalizedValue = el.value.replace(/\r\n/g, '\n');

      // Create a working TextRange that lives only in the input
      textInputRange = el.createTextRange();
      textInputRange.moveToBookmark(range.getBookmark());

      // Check if the start and end of the selection are at the very end
      // of the input, since moveStart/moveEnd doesn't return what we want
      // in those cases
      endRange = el.createTextRange();
      endRange.collapse(false);

      if (textInputRange.compareEndPoints('StartToEnd', endRange) > -1) {
        start = end = len;
      } else {
        start = -textInputRange.moveStart('character', -len);
        start += normalizedValue.slice(0, start).split('\n').length - 1;

        if (textInputRange.compareEndPoints('EndToEnd', endRange) > -1) {
          end = len;
        } else {
          end = -textInputRange.moveEnd('character', -len);
          end += normalizedValue.slice(0, end).split('\n').length - 1;
        }
      }
    }
  }

  return {
    start: start,
    end: end,
  };
}

const offsetToRangeCharacterMove = (el, offset) => {
  return offset - (el.value.slice(0, offset).split('\r\n').length - 1);
};

const setSelection = (el, start, end) => {
  if (
    typeof el.selectionStart == 'number' &&
    typeof el.selectionEnd == 'number'
  ) {
    el.selectionStart = start;
    el.selectionEnd = end;
  } else if (typeof el.createTextRange != 'undefined') {
    var range = el.createTextRange();
    var startCharMove = offsetToRangeCharacterMove(el, start);
    range.collapse(true);
    if (start == end) {
      range.move('character', startCharMove);
    } else {
      range.moveEnd('character', offsetToRangeCharacterMove(el, end));
      range.moveStart('character', startCharMove);
    }
    range.select();
  }
};

export const insertTextAtCaret = (el, text) => {
  var pos = getInputSelection(el).end;
  var newPos = pos + text.length;
  var val = el.value;
  el.value = val.slice(0, pos) + text + val.slice(pos);
  setSelection(el, newPos, newPos);
};

export const isValidUrl = string => {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};

export const isValidDomain = domain => {
  var re = new RegExp(
    /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
  );
  return domain.match(re);
};

export const characterCount = (maxLength, ref) => {
  return ref.current
    ? `${maxLength -
    ref.current.value.length}/${maxLength} characters remaining`
    : `${maxLength}/${maxLength} characters remaining`;
};

export const analyticsSendEvent = (event, args = {}) => {
  TagManager.dataLayer({
    dataLayer: {
      event: event,
      ...args,
    },
  });
};

export default {};
